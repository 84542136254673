<template>
  <div>
    <PageHeader :items="items" :optionalItems="optionalItems" />
    <div class="custom-search d-flex justify-content-between mb-1">
      <b-form-group>
        <div class="d-flex align-items-center">
          <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
          <b-form-select
            v-model="pageLength"
            :options="['25', '50', '100']"
            class="ml-1"
            @input="(value) => onPerPageChange({ pageLength: value })"
          />
        </div>
      </b-form-group>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="dextop-table-view">
      <div class="table-responsive api-keys-table">
        <!-- table -->
        <vue-good-table
          styleClass="vgt-table striped"
          :columns="columns"
          :fixed-header="true"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
            trigger: 'enter',
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-per-page-change="onPerPageChange"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'action'">
              <feather-icon
                v-if="$can(`${$route.meta.permission}.edit`)"
                @click="$router.push(`/permissions/${props.row.id}/edit`)"
                size="16"
                icon="Edit2Icon"
                class="mr-50"
              />
              <feather-icon
                v-if="$can(`${$route.meta.permission}.delete`)"
                @click="destroy(props.row.id)"
                size="16"
                icon="TrashIcon"
                class="mr-50"
              />
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-end flex-wrap">
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="totalRecords"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => onPageChange({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="reponsive-view-cards">
      <div class="row">
        <div class="col-md-4" v-for="row in rows" :key="row.id">
          <div class="card mb-1">
            <div class="card-header">
              <h4>{{ row.title }}</h4>
              <div class="d-flex justify-content-end gap-2">
                <feather-icon
                  v-if="$can(`${$route.meta.permission}.edit`)"
                  @click="$router.push(`/permissions/${row.id}/edit`)"
                  size="18"
                  icon="Edit2Icon"
                  class="cursor-pointer"
                />
                <feather-icon
                  v-if="$can(`${$route.meta.permission}.delete`)"
                  @click="destroy(row.id)"
                  size="18"
                  icon="TrashIcon"
                  class="cursor-pointer"
                />
              </div>
            </div>
            <div class="card-body">
              <b-card-text>
                <strong>{{ $t("Scope") }}:</strong> {{ row.scope }}
              </b-card-text>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-2">
        <b-pagination
          :value="1"
          :total-rows="totalRecords"
          :per-page="pageLength"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @input="(value) => onPageChange({ currentPage: value })"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
    <!--====================================-->
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";

export default {
  components: {
    PageHeader,
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t("Title"),
          field: "title",
          sortable: false,
        },
        {
          label: this.$t("Scope"),
          field: "scope",
          sortable: false,
        },
        {
          label: this.$t("Action"),
          field: "action",
          sortable: false, // disable sorting on this column
        },
      ];
    },
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Permissions"),
          active: true,
        },
      ];
    },
    optionalItems() {
      return {
        createBtn1: {
          show: true,
          text: this.$t("Create Permission"),
          icon: "PlusIcon",
          path: "/permissions/create",
          permission: "permission.create",
        },
      };
    },
  },
  data() {
    return {
      totalRecords: 0,
      pageLength: 50,
      page: 1,
      rows: [],
      searchTerm: "",
    };
  },

  created() {
    this.loadItems();
  },

  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },

    onPageChange(params) {
      this.page = params.currentPage;
      console.log(this.page);
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },

    onSortChange(params) {
      this.sortType = params[0].type;
      this.sortField = params[0].field;
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.$store.commit("showLoader", true);
        let response = await this.$store.dispatch("permissions/list", {
          limit_start: this.pageLength * (this.page - 1),
          limit_count: this.pageLength,
          search_string: this.searchTerm,
        });
        this.rows = response?.data?.data;
        this.totalRecords = response?.data?.count;
      } catch (e) {
        console.error("api error ___", e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    async destroy(id) {
      const confirmed = await this.showConfirmationDialog({
        title: this.$t("Do you want to delete this record?"),
        subText: this.$t("Yes delete it!"),
      });

      if (confirmed) {
        await this.$store.dispatch("permissions/destroy", {
          id: id,
        });
        this.loadItems();
      }
    },
  },
};
</script>

<style>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
